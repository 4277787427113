import moment from 'moment-timezone';
import { getToken, getCachedUser, isErrorInteractionRequired } from './auth/msal-config';
import analytics from './analytics';

const SOCKET_OPEN = 1;
const CONNECTION_RETRY_INTERVAL_MS = 5000;
const CONNECTION_RETRY_TOKEN_ERROR_INTERVAL_MS = 60000;
const PULSE_INTERVAL_MS = 15000;
const PULSE_THRESHOLD_MS = 20000;

const DEBUG = true;
const ANALYTICS_SCREEN = 'socket.js';

const getSecondsSince = startMoment => (startMoment
  ? moment.duration(moment().diff(startMoment)).as('seconds')
  : 0);

const connect = (handlers = {}) => {
  let connection;
  let lastPulse;
  let pulseInterval;
  let pingAttrs;
  let connectionOnOpenTime;
  let connectionOnCloseTime;
  let connectionAttempts = 0;

  const trackThrottled = (trackParams) => {
    if (connectionAttempts < 3) {
      analytics.track(trackParams);
    }
  };

  const openConnection = () => {
    if (connection && connection.readyState < 2) {
      // Already has connection or is reconnecting
      return;
    }
    connectionAttempts += 1;

    const user = getCachedUser();
    console.log('Connecting as', user, process.env.WEBSOCKET_API_BASE);
    trackThrottled({
      name: 'Socket connecting',
      screen: ANALYTICS_SCREEN,
    });

    getToken().then((token) => {
      if (DEBUG) console.log('Got token');
      connection = new WebSocket(`${process.env.WEBSOCKET_API_BASE}/food/?access_token=${token}`);
      connection.onopen = (event) => {
        if (DEBUG) console.log('Socket opened ', event);
        const message = `attempt: ${connectionAttempts} closedSeconds: ${getSecondsSince(connectionOnCloseTime)}`;
        connectionAttempts = 0;
        connectionOnOpenTime = moment();
        connectionOnCloseTime = undefined;
        trackThrottled({
          name: 'Socket onopen',
          screen: ANALYTICS_SCREEN,
          value: message,
        });
        lastPulse = moment();
        if (typeof handlers.onopen === 'function') {
          handlers.onopen(event);
        }

        // For debug purposes, close the socket after one minute to trigger getToken often
        // setTimeout(() => {
        //   console.log('Debug closing connection');
        //   connection.close();
        // }, 60000);
      };

      connection.onmessage = (event) => {
        const data = JSON.parse(event.data);
        if (DEBUG) console.log('Connection onmessage', data);
        if (data.action === 'pong') {
          lastPulse = moment();
        }

        if (typeof handlers.onmessage === 'function') {
          handlers.onmessage(event);
        }
      };

      pulseInterval = setInterval(() => {
        if (lastPulse) {
          const lastPulseSince = moment.duration(moment().diff(lastPulse)).as('milliseconds');
          if (connection.readyState === SOCKET_OPEN && lastPulseSince >= PULSE_THRESHOLD_MS) {
            if (DEBUG) console.warn(`Last pulse over ${PULSE_THRESHOLD_MS / 1000} seconds ago, closing socket`);
            connection.close();
          }
        }
        if (connection.readyState === SOCKET_OPEN) {
          connection.send(JSON.stringify({
            action: 'ping',
            ...pingAttrs,
          }));
        }
      }, PULSE_INTERVAL_MS);

      connection.onclose = (event) => {
        const errorMsg = `Socket (readyState: ${connection.readyState}) is closed (Code: ${event.code} Reason: ${event.reason})`;
        trackThrottled({
          name: 'Socket onclose',
          value: errorMsg,
          screen: ANALYTICS_SCREEN,
        });
        if (DEBUG) console.error(errorMsg);
        if (!connectionOnCloseTime) {
          connectionOnCloseTime = moment();
        }
        clearInterval(pulseInterval);
        if (typeof handlers.onerror === 'function') {
          handlers.onerror(new Error(errorMsg));
        }

        setTimeout(() => {
          trackThrottled({
            name: 'Execute open connection due to connection onclose',
            value: getSecondsSince(connectionOnOpenTime),
            screen: ANALYTICS_SCREEN,
          });
          openConnection();
        }, CONNECTION_RETRY_INTERVAL_MS);
      };

      connection.onerror = (event) => {
        const errorMsg = `Socket (readyState: ${connection.readyState}) error (Code: ${event.code} Reason: ${event.reason})`;
        trackThrottled({
          name: 'Socket onerror',
          value: errorMsg,
          screen: ANALYTICS_SCREEN,
        });
        if (DEBUG) console.error(errorMsg);
        if (typeof handlers.onerror === 'function') {
          handlers.onerror(new Error(errorMsg));
        }
        setTimeout(() => {
          trackThrottled({
            name: 'Execute open connection due to connection onerror',
            value: getSecondsSince(connectionOnOpenTime),
            screen: ANALYTICS_SCREEN,
          });
          openConnection();
        }, CONNECTION_RETRY_INTERVAL_MS);
      };
    }).catch((error) => {
      trackThrottled({
        name: 'MSAL getToken failed',
        screen: ANALYTICS_SCREEN,
        value: error ? error.message : '',
      });
      console.error('MSAL getToken() failed: ', error);
      if (typeof handlers.onerror === 'function') {
        handlers.onerror(new Error(error.message));
      }
      if (isErrorInteractionRequired(error)) {
        // Force reload page
        trackThrottled({
          name: 'MSAL getToken failed force reload',
          screen: ANALYTICS_SCREEN,
          value: error ? error.message : '',
        });
        window.location.reload();
      } else {
        // Keep retry loop alive
        trackThrottled({
          name: 'Execute open connection due to getToken error',
          value: getSecondsSince(connectionOnOpenTime),
          screen: ANALYTICS_SCREEN,
        });
        console.log('Retry after ms', CONNECTION_RETRY_TOKEN_ERROR_INTERVAL_MS)
        setTimeout(() => {
          openConnection();
        }, CONNECTION_RETRY_TOKEN_ERROR_INTERVAL_MS);
      }
    });
  };

  console.log('Initial openConnection after 5sec');
  setTimeout(() => {
    console.log('Initial openConnection execute');
    openConnection();
  }, 5000)

  return () => ({
    webSocket: connection,
    setPingAttrs: (attrs) => { pingAttrs = attrs; },
  });
};

export default {
  connect,
};
