import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment-timezone';
import { ToastContainer, toast } from 'react-toastify';
import keyBy from 'lodash.keyby';
import 'react-toastify/dist/ReactToastify.css';
import Api from './api/blacklist';
import sounds from './sounds';
import analytics from './analytics';
import socket from './socket';
import appLock from './app-lock';

import 'normalize.css';
import './appIndex.css';

import './loading.css';
import './loading-btn.css';

import Header from './components/header';
import OrdersView from './components/orders/view';
import QueueOptions from './components/queueOptions';
import OrderSettings from './components/orderSettings';
import Buffets from './components/buffets';
import Confirm from './components/confirm';
import CashierReportView from './components/cashier-report/view';
import Notification from './components/notification';
import FullScreenNotification from './components/fullScreenNotification';
import LockedView from './components/lockedView';
import ChangePinView from './components/change-pin-view';
import FullScreenError from './components/fullScreenError';
import BlacklistView from './components/blacklist/blacklistView';
import BlacklistBanner from './components/blacklist/blacklistBanner';
import {
  filterAndGroupOrders,
  resolveOrderItems,
  resolveOrderItemsSorted,
  orderWithUrgencyData,
  getFirstOrderOfFirstStatus,
  timeInMinutesOrTime,
  groupOrdersByUrgencyLevel,
  getUrgencyLevelChangedOrders,
  setInitialView,
  deliveredOrdersFilter,
  getBlacklistedPortionCount,
  getBlacklistHeaderText,
  orderWithUrgencyTimeData,
  deliveryEndComparator,
  hasDeliveryWhenReadyInQueue,
} from './util';
import printUtil from './print-util';

import {
  VIEWS, VIEW_KITCHEN,
  ORDER_STATUS_AUTHORIZATION_FINISHED,
  ORDER_STATUS_PRODUCTION_STARTED,
  ORDER_STATUS_DELIVERED,
  ORDER_STATUS_PRODUCTION_FINISHED,
  VIEW_OPTIONS_VIEWS, VIEW_OPTIONS_QUEUE,
  VIEW_ORDER_SETTINGS,
  VIEW_BUFFETS,
  SECONDARY_ITEM_TYPES,
  URGENCY_LEVELS,
  VIEW_CASHIER_REPORT,
  VIEW_CHANGE_PIN,
  VIEW_FRONT_OF_HOUSE,
  VIEW_DELIVERY_HISTORY,
  VIEW_CONFIRM,
  CONFIRM_TITLE_TABLE_NUMBER_MISSING,
  CONFIRM_TITLE_DO_YOU_WANT_TO_START,
  CONFIRM_TITLE_X_MINUTES_TO_DESIRED_DELIVERY_TIME,
  VIEW_KITCHEN_AND_FRONT_OF_HOUSE,
  VIEW_MANAGE_PORTIONS,
  VIEW_MARKET_ORDERS,
  URGENCY_LEVEL_HIGH,
} from './constants';
import MarketOrders from './components/marketOrders';

moment.tz.setDefault('Europe/Helsinki');

const IDLE_TIMEOUT_SECONDS = 30;
const TICK_INTERVAL_MS = 1000;
const PENDING_ACTION_TIMEOUT_MS = 5000;
const ORDERS_REFRESH_INTERVAL_MS = 60000;
const APP_RELOAD_INTERVAL_MS = 4*60*60*1000; // 4 hours
const SHOW_VIEW_CASHIER_REPORT = `${process.env.SHOW_VIEW_CASHIER_REPORT}` === 'true';

const uniqueOrderStatusesOfAllViews = Object.values(VIEWS)
  .reduce((acc, { orderStatuses }) => [
    ...orderStatuses.filter(status => acc.indexOf(status) === -1),
    ...acc,
  ], []);

export default class App extends React.Component {
  static printOrder(order) {
    const orderWithoutIrrelevantItems = {
      ...order,
      items: resolveOrderItemsSorted(order),
    };

    if (typeof ABCAndroid === 'object') {
      // eslint-disable-next-line no-undef
      const paperWidth = ABCAndroid.getPaperWidthDots();
      // eslint-disable-next-line no-undef
      const useCutter = ABCAndroid.getPrintCutter();
      console.log('paperWidth', paperWidth, 'useCutter', useCutter);
      const zpl = printUtil.createOrderReceiptZpl(orderWithoutIrrelevantItems, paperWidth, useCutter);
      // eslint-disable-next-line no-undef
      ABCAndroid.printZpl(zpl);
      // ABCAndroid.printOrder(JSON.stringify(orderWithoutIrrelevantItems));
    } else {
      console.log('Could not find ABCAndroid object');
      // width 640 and useCutter true are the default settings in native app
      const zpl = printUtil.createOrderReceiptZpl(orderWithoutIrrelevantItems, 640, true);
      console.log('Would have printed ZPL', zpl);
    }
  }

  static isUpdateStatusActionFulfilled(action) {
    return order => action.action === 'updateStatus'
      && action.order.orderId === order.orderId
      && action.order.status === order.status;
  }

  static isUpdateQueueActionFulfilled(action, pob) {
    return action.action === 'updateQueue'
      && action.pob.foodQueueMinutes === pob.foodQueueMinutes;
  }

  static errorView({ error }) {
    return (
      <Notification
        title="Yhteysvirhe"
        description={error.message}
        action={{ onClick: () => window.location.reload(true), text: 'Yritä uudelleen' }}
      />
    );
  }

  static loaderView() {
    return (<Notification title="Haetaan tilauksia" />);
  }

  static viewButton({ currentView, onViewSelect }) {
    return ([viewKey, view]) => (
      <div
        key={viewKey}
        className={viewKey === currentView
          ? 'view-options__button-selected view-options__button'
          : 'view-options__button'}
        onClick={() => onViewSelect(viewKey)}
      >
        {view.title}
      </div>
    );
  }

  static viewActionButton({ onViewActionSelect, managePortionsTitle }) {
    return ([viewKey, view]) => (
      <div
        key={viewKey}
        className={viewKey === VIEW_MANAGE_PORTIONS
          ? 'view-actions__button view-actions__portion-manager'
          : 'view-actions__button'}
        onClick={() => onViewActionSelect(viewKey)}
      >
        {viewKey !== VIEW_MANAGE_PORTIONS && view.title}
        {viewKey === VIEW_MANAGE_PORTIONS && (managePortionsTitle)}
      </div>
    );
  }

  static viewOptionsView({
    currentView, onViewSelect, onOptionsViewSelect, managePortionsTitle,
  }) {
    const optionViews = VIEWS[currentView] && VIEWS[currentView].VIEWS && Object.entries(VIEWS[currentView].VIEWS)
      .filter(optionView => SHOW_VIEW_CASHIER_REPORT || optionView[0] !== VIEW_CASHIER_REPORT);
    return (
      <div className="fill-area view-options">
        <span className="view-options__title">Näkymän valinta</span>
        <div className="view-options__buttons">
          {Object.entries(VIEWS).map(App.viewButton({ currentView, onViewSelect }))}
        </div>
        {optionViews && optionViews.length && (
        <div className="view-actions">
          <span className="view-actions__title">Asetukset</span>
            {optionViews.map(App.viewActionButton({
              onViewActionSelect: onOptionsViewSelect,
              managePortionsTitle,
            }))}
        </div>
        )}
      </div>
    );
  }

  static showErrorToast(text) {
    toast(
      ({ closeToast }) => (
        <div className="error-toast">
          <div className="error-toast-content">
            {text}
          </div>
          <button className="error-toast-close-button" type="button" onClick={closeToast}>
          Sulje
          </button>
        </div>
      ), {
        className: 'error-toast-container',
        bodyClassName: 'error-toast-container-body',
        position: toast.POSITION.TOP_CENTER,
        closeOnClick: false,
        closeButton: false,
      },
    );
  }

  static checkForNewBufferOrders(state, buffetOrders) {
    const { currentView } = state;
    const buffetOrderCount = buffetOrders.filter(deliveredOrdersFilter).length;
    const stateBuffetOrderCount = state.buffetOrders !== null && state.buffetOrders !== undefined ? state.buffetOrders.filter(deliveredOrdersFilter).length : 0;
    const receivedNewBuffetOrder = buffetOrderCount > stateBuffetOrderCount;
    const playNewBuffetOrderSound = (currentView === VIEW_FRONT_OF_HOUSE || currentView === VIEW_KITCHEN_AND_FRONT_OF_HOUSE) && receivedNewBuffetOrder;

    if (playNewBuffetOrderSound) {
      console.log('new buffet order received, should play sound');
      sounds.playSound('newOrder');
    }
  }

  constructor(props) {
    super(props);
    /**
     * Orders refresh interval
     * To make sure that the restaurant app gets new order even if the first message is lost.
     */
    this.ordersRefreshInterval = null;

    /**
     * Ticker interval
     * 1s ticker to update urgency data.
     */
    this.tickerInterval = null;
    /**
     * App reload interval
     * Testing if this helps to keep the AD session
     */
    this.appReloadInterval = null;
    this.state = {
      // all restaurant orders, unprocessed
      orders: null,
      // all restaurant orders, urgency and time left added to orders, grouped by status
      ordersWithUrgencyDataGroupedByStatus: null,
      // same as ordersWithUrgencyDate but only statuses that are shown in this view (currentView)
      viewOrders: null,
      // viewOrders but grouped by urgency level, not status
      viewOrdersByUrgencyLevel: null,
      // buffet orders, separated from restaurant orders (that have a production flow)
      buffetOrders: null,
      selectedOrder: null,
      pendingActions: [],
      currentView: props.initialView || VIEW_KITCHEN,
      currentOptionsView: null,
      showOptions: null,
      pob: null,
      loaderRunning: false,
      selectedReport: null,
      lastUserInteraction: props.initialTime,
      userIdle: false,
      continuousUrgencyAlertForOrders: [],
      localTime: props.initialTime,
      locked: props.locked,
      nextStatus: null,
      confirmed: false,
      confirmTitle: null,
      marketOrders: null,
    };

    this.moveToStatus = this.moveToStatus.bind(this);
    this.onError = this.onError.bind(this);
    this.toggleOptions = this.toggleOptions.bind(this);
    this.doesNotNeedConfirmationOrIsAlreadyConfirmed = this.doesNotNeedConfirmationOrIsAlreadyConfirmed.bind(this);
    this.onViewSelect = this.onViewSelect.bind(this);
    this.onQueueSelect = this.onQueueSelect.bind(this);
    this.onOptionsViewSelect = this.onOptionsViewSelect.bind(this);
    this.userInteractionHandler = this.userInteractionHandler.bind(this);
    this.onOrderSelect = this.onOrderSelect.bind(this);
    this.lock = this.lock.bind(this);
    this.unlock = this.unlock.bind(this);
    this.onPinChanged = this.onPinChanged.bind(this);
    this.togglePortionAvailability = this.togglePortionAvailability.bind(this);
    this.mapAvailabilityToPortions = this.mapAvailabilityToPortions.bind(this);
    this.getConnection = socket.connect({
      onmessage: (event) => {
        const { menu } = this.state;
        const stateUpdates = App.onDataUpdate(this.state, { ...event, data: JSON.parse(event.data) }, () => this.getConnection());
        this.setState((state) => {
          const { action } = JSON.parse(event.data);
          const ordersUpdated = action === 'ordersUpdated';
          if (ordersUpdated && stateUpdates.buffetOrders !== undefined && stateUpdates.buffetOrders.length > 0) {
            App.checkForNewBufferOrders(state, stateUpdates.buffetOrders);
          }
          return stateUpdates;
        });
        // initial blacklist/menu fetch
        if (!menu) {
          Api.fetchPortionBlacklistForPob(this.mapAvailabilityToPortions);
        }
      },
      onerror: this.onError,
    });
  }

  componentWillMount() {
    document.body.addEventListener('click', this.userInteractionHandler);
    this.tickerInterval = setInterval(() => {
      const updatedState = App.onDataUpdate(
        this.state,
        {
          data: {
            action: 'tick',
          },
        },
        this.getConnection,
      );
      this.setState(updatedState);
      if (updatedState && updatedState.locked === true) {
        appLock.lock();
      }
    }, TICK_INTERVAL_MS);

    this.ordersRefreshInterval = setInterval(() => {
      console.log('refresh orders');
      const { webSocket } = this.getConnection();
      webSocket.send(JSON.stringify({
        action: 'refresh',
      }));
    }, ORDERS_REFRESH_INTERVAL_MS);

    this.appReloadInterval = setInterval(() => {
      const { currentView } = this.state;
      console.log('App reload interval');
      analytics.track({
        name: 'App reload interval',
        value: moment().toISOString(),
        screen: currentView,
      });
      window.location.reload();
    }, APP_RELOAD_INTERVAL_MS);
  }

  componentWillUnmount() {
    const { webSocket } = this.getConnection();
    if (webSocket) {
      webSocket.close();
    }
    document.body.removeEventListener('click', this.userInteractionHandler);
    clearInterval(this.tickerInterval);
    clearInterval(this.ordersRefreshInterval);
    clearInterval(this.appReloadInterval);
  }

  onError(error) {
    this.setState({
      orders: error,
      pendingActions: [], // reset so that we don't have disabled buttons once connection is re-established
    });
  }

  onViewSelect(view) {
    const { ordersWithUrgencyDataGroupedByStatus, pob } = this.state;
    let ordersUpdates;
    if (ordersWithUrgencyDataGroupedByStatus !== null) {
      const viewOrders = ordersWithUrgencyDataGroupedByStatus.filter(({ status }) => (
        VIEWS[view].orderStatuses.includes(status)));
      ordersUpdates = {
        selectedOrder: getFirstOrderOfFirstStatus(viewOrders),
        viewOrders,
      };
    }
    this.setState({
      currentView: view,
      ...ordersUpdates,
    });
    setInitialView(view);
    analytics.trackPageView(view, pob ? pob.pobId : '');
  }

  onOptionsViewSelect(optionsView) {
    const { pob } = this.state;
    this.setState({
      currentOptionsView: optionsView,
    });
    analytics.trackPageView(optionsView, pob ? pob.pobId : '');
  }

  onQueueSelect(queueOption) {
    const { currentView, pob } = this.state;
    const { webSocket } = this.getConnection();
    if (webSocket) {
      this.setState({ loaderRunning: true });
      this.sendAndTrack({
        action: 'updateQueue',
        pob: {
          foodQueueMinutes: queueOption,
        },
      });
      analytics.track({
        name: 'Update queue',
        value: queueOption,
        screen: currentView,
        pobId: pob.pobId,
      });
    }
  }

  /**
   * The main data handler for websocket events.
   * A reducer, takes event (action) and current state, updates state.
   * @param state {object} Current state
   * @param {{data: {action: string, orders: [object], pob: object timestamp: string}}} event Websocket event
   * @returns stateUpdates {object} State updates
   */
  static onDataUpdate(state, event, getConnection) {
    const {
      selectedOrder,
      currentView,
      pendingActions,
      viewOrders,
      orders,
      continuousUrgencyAlertForOrders,
      lastUserInteraction,
      localTime,
      pob,
      userIdle,
      loaderRunning,
      locked,
      showOptions,
    } = state;
    const { data } = event;
    let stateUpdates;
    switch (data.action) {
      case 'pong':
        // synchronize localTime with server time
        stateUpdates = {
          localTime: moment(data.localTimestamp),
        };
        break;
      case 'ordersFound':
      case 'ordersUpdated': {
        const restaurantOrders = data.orders.filter(order => !order.type || order.type === 'ORDER_RESTAURANT');
        const buffetOrders = data.orders.filter(order => order.type === 'ORDER_BUFFET').reverse();
        const newOrdersWithUrgencyDateGroupedByStatus = filterAndGroupOrders(
          restaurantOrders.map(order => orderWithUrgencyData(order, localTime)),
          uniqueOrderStatusesOfAllViews,
        );
        const newViewOrders = newOrdersWithUrgencyDateGroupedByStatus.filter(({ status }) => (
          VIEWS[currentView].orderStatuses.includes(status)));
        // eslint-disable-next-line no-shadow
        let currentSelectedOrder = selectedOrder && newViewOrders.reduce((ordersFlat, { orders }) => (
          [...ordersFlat, ...orders]
        ), []).find(order => order.orderId === selectedOrder.orderId);

        // Check if this order update resolves any pending actions
        const newPendingActions = pendingActions.reduce((acc, pendingAction) => {
          // NOTE: resolvedOrder does not contain urgency data, is raw order data
          const resolvedOrder = restaurantOrders.find(App.isUpdateStatusActionFulfilled(pendingAction.action));
          if (resolvedOrder === undefined) {
            // Action still unresolved
            acc.push(pendingAction);
          } else if (pendingAction.action.action === 'updateStatus') {
            // Order action resolved, do special post update effects

            if (pendingAction.action.order.status === ORDER_STATUS_PRODUCTION_STARTED) {
              // Print bong receipt
              App.printOrder(resolvedOrder);
            }

            if (currentSelectedOrder
              && currentSelectedOrder.orderId === resolvedOrder.orderId
              && VIEWS[currentView].orderStatuses.includes(resolvedOrder.status) === false) {
              // Don't keep transitioned order as selected, no longer in view
              currentSelectedOrder = null;
            }
          }
          return acc;
        }, []);

        // TODO extract, Resolve if there's new orders
        const currentOrdersInQueue = orders && orders instanceof Error === false
          ? viewOrders.find(({ status }) => status === ORDER_STATUS_AUTHORIZATION_FINISHED)
          : null;
        const currentOrdersInQueueOrderIds = currentOrdersInQueue
          ? currentOrdersInQueue.orders.map(order => order.orderId)
          : null;
        const ordersInQueue = newViewOrders.find(({ status }) => status === ORDER_STATUS_AUTHORIZATION_FINISHED);
        const ordersInQueueOrderIds = ordersInQueue ? ordersInQueue.orders.map(order => order.orderId) : null;

        const readyNewOrders = newViewOrders.find(({ status }) => status === ORDER_STATUS_PRODUCTION_FINISHED);
        const newOrdersWithTableNumberGiven = readyNewOrders ? readyNewOrders.orders.filter(o => o.delivery && o.delivery.table) : null;
        if (newOrdersWithTableNumberGiven) {
          const readyExistingOrders = viewOrders && viewOrders.find(({ status }) => status === ORDER_STATUS_PRODUCTION_FINISHED);
          const readyExistingOrderIds = readyExistingOrders && readyExistingOrders.orders && readyExistingOrders.orders.length > 0
            ? readyExistingOrders.orders.filter(o => !o.delivery.table).map(order => order.orderId)
            : [];
          const tableNumberGivenForExistingOrder = newOrdersWithTableNumberGiven.some(newOrder => readyExistingOrderIds.includes(newOrder.orderId));
          if (tableNumberGivenForExistingOrder) {
            sounds.playSound('tableNumberGiven');
          }
        }

        const newOrders = currentOrdersInQueueOrderIds && ordersInQueueOrderIds
          ? ordersInQueueOrderIds.reduce((acc, orderId) => (
            currentOrdersInQueueOrderIds.includes(orderId) === false
              ? [...acc, orderId]
              : acc
          ), [])
          : [];
        // End of resolving if there's new orders

        const newOrdersInQueueOrderIds = restaurantOrders.filter(({ status }) => status === ORDER_STATUS_AUTHORIZATION_FINISHED).map(({ orderId }) => orderId);
        const newContinuousUrgencyAlertForOrders = continuousUrgencyAlertForOrders.filter(orderId => newOrdersInQueueOrderIds.includes(orderId));

        stateUpdates = {
          orders: restaurantOrders,
          buffetOrders,
          ordersWithUrgencyDataGroupedByStatus: newOrdersWithUrgencyDateGroupedByStatus,
          viewOrders: newViewOrders,
          viewOrdersByUrgencyLevel: groupOrdersByUrgencyLevel(newViewOrders),
          selectedOrder: currentSelectedOrder
          || getFirstOrderOfFirstStatus(newViewOrders),
          pendingActions: newPendingActions,
          continuousUrgencyAlertForOrders: newContinuousUrgencyAlertForOrders,
        };

        if (newOrders.length > 0) {
          console.log('New orders: ', newOrders);
          sounds.playSound('newOrder');

          newOrders.forEach(orderId => analytics.track({
            name: 'New order',
            value: orderId,
            screen: currentView,
            pobId: pob.pobId,
          }));
        }
        break;
      }
      case 'pobDataUpdated': {
        const newPendingActions = pendingActions.length > 0 && pendingActions.reduce((acc, pendingAction) => (
          App.isUpdateQueueActionFulfilled(pendingAction.action, data.pob)
            ? acc
            : [...acc, pendingAction]
        ), []);

        stateUpdates = {
          pob: data.pob,
          ...(loaderRunning && { loaderRunning: false }),
          ...(newPendingActions && { pendingActions: newPendingActions }),
        };

        if (!pob && data.pob) {
          // First time receiving pob data, track initial page view
          analytics.setLastKnownPobId(data.pob.pobId);
          analytics.trackPageView(currentView, data.pob.pobId);
        }
        break;
      }
      case 'tick': {
        const newTime = localTime.add(TICK_INTERVAL_MS, 'milliseconds');
        // TODO extract, same as in ordersUpdated
        const newOrdersWithUrgencyDateGroupedByStatus = orders instanceof Error === false && orders !== null
          ? filterAndGroupOrders(orders.map(order => orderWithUrgencyData(order, newTime)), uniqueOrderStatusesOfAllViews)
          : null;
        // TODO extract, same as in ordersUpdated
        const newViewOrders = newOrdersWithUrgencyDateGroupedByStatus
          ? newOrdersWithUrgencyDateGroupedByStatus.filter(({ status }) => (
            VIEWS[currentView].orderStatuses.includes(status)))
          : null;
        // TODO extract, same as in ordersUpdated
        const viewOrdersByUrgencyLevel = newViewOrders
          ? groupOrdersByUrgencyLevel(newViewOrders)
          : null;

        // TODO extract, same as in ordersUpdated
        // eslint-disable-next-line no-shadow
        const currentSelectedOrder = selectedOrder && newViewOrders && newViewOrders.reduce((ordersFlat, { orders }) => (
          [...ordersFlat, ...orders]
        ), []).find(order => order.orderId === selectedOrder.orderId);

        // Check if we should abandon pending actions and show an error
        let updateLoaderRunning;
        const newPendingActions = pendingActions.reduce((acc, pendingAction) => {
          if ((new Date()).getTime() - pendingAction.timestamp < PENDING_ACTION_TIMEOUT_MS) {
            // Action still pending
            acc.push(pendingAction);
          } else if (pendingAction.action.action === 'updateStatus') {
            // Update status action timed out
            const { displayName } = pendingAction.action.order;
            App.showErrorToast(`Virhe tilauksen ${displayName} siirtämisessä jonossa. Kokeile uudestaan.`);
          } else if (pendingAction.action.action === 'updateQueue') {
            // Update queue action timed out
            updateLoaderRunning = { loaderRunning: false };
            App.showErrorToast('Virhe jonon pituuden muuttamisessa. Kokeile uudestaan.');
          } else {
            console.warn(`Unknown pending action "${pendingAction.action.action}" time out`);
          }
          return acc;
        }, []);

        if (getConnection) {
          getConnection().setPingAttrs({
            view: showOptions ? 'OPTIONS' : currentView,
            version: process.env.REV,
          });
        }

        stateUpdates = {
          localTime: newTime,
          ordersWithUrgencyDataGroupedByStatus: newOrdersWithUrgencyDateGroupedByStatus,
          viewOrders: newViewOrders,
          viewOrdersByUrgencyLevel,
          selectedOrder: currentSelectedOrder,
          pendingActions: newPendingActions,
          ...(loaderRunning && updateLoaderRunning),
        };
        break;
      }
      case 'marketOrdersUpdated': {
        const marketOrders = data.orders.map(o => orderWithUrgencyTimeData(o, localTime)).sort(deliveryEndComparator);
        const shouldPlaySound = marketOrders.find(order => order.urgencyLevel === URGENCY_LEVEL_HIGH) && (currentView === VIEW_FRONT_OF_HOUSE || currentView === VIEW_KITCHEN_AND_FRONT_OF_HOUSE);
        stateUpdates = {
          marketOrders,
        };
        if (shouldPlaySound) {
          sounds.playSound('marketOrderUrgency3');
        }
        break;
      }
      default:
        // Event has no handler, return
        return;
    }

    const newUserIdle = currentView !== VIEW_DELIVERY_HISTORY
    && !showOptions
    && !userIdle
    && lastUserInteraction
      ? moment.duration(localTime.diff(lastUserInteraction)).as('seconds') >= IDLE_TIMEOUT_SECONDS
      : undefined;

    if (stateUpdates.viewOrders && viewOrders) {
      // Use already modified continuous alerts or current alerts as default
      let newContinuousUrgencyAlertForOrders = stateUpdates.continuousUrgencyAlertForOrders || continuousUrgencyAlertForOrders;

      // TODO extract, resolve if there's sounds to be played
      // Any order transitioned from urgency level to another?
      const ordersInQueueNew = stateUpdates.viewOrders.find(
        orderGroup => orderGroup.status === ORDER_STATUS_AUTHORIZATION_FINISHED,
      );
      const ordersInQueueOld = viewOrders.find(({ status }) => status === ORDER_STATUS_AUTHORIZATION_FINISHED);

      const alertSoundForOrders = ordersInQueueOld && ordersInQueueOld.orders
      && ordersInQueueNew && ordersInQueueNew.orders
        ? getUrgencyLevelChangedOrders(ordersInQueueOld.orders, ordersInQueueNew.orders)
        : [];

      if (alertSoundForOrders.length > 0) {
        console.log('alertSoundForOrders', alertSoundForOrders);
        // Play orderUrgency2 once if there new order(s) on urgency level 2
        if (alertSoundForOrders.find(({ urgencyLevel }) => urgencyLevel === 2)) {
          sounds.playSound('orderUrgency2');
        }
        // Play orderUrgency3 later if there's new order(s) on urgency level 3
        newContinuousUrgencyAlertForOrders = [
          ...continuousUrgencyAlertForOrders,
          ...alertSoundForOrders.filter(({ urgencyLevel }) => urgencyLevel === 3).map(({ orderId }) => orderId),
        ];
      }
      // End of resolving if there's sounds to be played

      if (newContinuousUrgencyAlertForOrders.length > 0 && (userIdle || newUserIdle)) {
        sounds.playSound('orderUrgency3');
      }

      stateUpdates = {
        ...stateUpdates,
        continuousUrgencyAlertForOrders: newContinuousUrgencyAlertForOrders,
      };
    }

    const idleLock = currentView === VIEW_FRONT_OF_HOUSE
      && newUserIdle === true
      && locked === false;

    const idleAndLockUpdates = {
      ...(newUserIdle !== undefined && newUserIdle !== userIdle && { userIdle: newUserIdle }),
      ...(idleLock && { locked: true }),
    };

    // eslint-disable-next-line consistent-return
    return {
      ...stateUpdates,
      ...idleAndLockUpdates,
    };
  }

  onOrderSelect(order) {
    this.setState({ selectedOrder: order });
  }

  onPinChanged() {
    const { currentView, pob } = this.state;
    this.setState({
      currentOptionsView: null,
    });
    analytics.track({
      name: 'Pin changed',
      value: '',
      screen: currentView,
      pobId: pob ? pob.pobId : '',
    });
  }

  mapAvailabilityToPortions(data) {
    if (data && data.menu && data.menu.portionGroups) {
      const portionGroups = data.menu.portionGroups.map(pg => ({
        ...pg,
        portions:
  pg.portions.map(portion => ({ ...portion, available: data.menu.stationBlacklist.indexOf(portion.ean) === -1 })),
      }));
      this.setState({ menu: { ...data.menu, portionGroups: [...portionGroups] } });
    }
  }

  sendAndTrack(action) {
    const { webSocket } = this.getConnection();
    if (webSocket) {
      const { pendingActions } = this.state;
      const newPendingAction = { timestamp: (new Date()).getTime(), action };
      this.setState({
        pendingActions: [...pendingActions, newPendingAction],
      });
      webSocket.send(JSON.stringify(action));
    }
  }

  moveToStatus(order, nextStatus) {
    if (order && nextStatus && this.doesNotNeedConfirmationOrIsAlreadyConfirmed(order, nextStatus)) {
      const { pob, currentView } = this.state;
      this.sendAndTrack({
        action: 'updateStatus',
        order: {
          orderId: order.orderId,
          displayName: order.displayName,
          status: nextStatus,
        },
      });
      analytics.track({
        name: `Move order to status ${nextStatus}`,
        value: order.orderId,
        screen: currentView,
        pobId: pob.pobId,
      });
    }
  }

  doesNotNeedConfirmationOrIsAlreadyConfirmed(order, nextStatus) {
    const {
      confirmed,
    } = this.state;

    const hours = ((order.timeLeftMinutes / 60) > 0) ? Math.floor(order.timeLeftMinutes / 60) : 0;
    const minutes = ((order.timeLeftMinutes % 60) > 0) ? order.timeLeftMinutes % 60 : 0;
    const hoursText = hours === 1 ? 'tunti' : 'tuntia';
    const minutesText = minutes === 1 ? 'minuutti' : 'minuuttia';
    const timeUntil = hours > 0 ? `${hours} ${hoursText} ${minutes} ${minutesText}` : `${minutes} ${minutesText}`;

    if (nextStatus === ORDER_STATUS_DELIVERED && !order.delivery.table && !confirmed) {
      this.setState({ showOptions: VIEW_CONFIRM, nextStatus, confirmTitle: CONFIRM_TITLE_TABLE_NUMBER_MISSING });
      return false;
    }

    if (nextStatus === ORDER_STATUS_PRODUCTION_STARTED
      && order.timeLeftMinutes > 15
      && !confirmed
      && !order.delivery.deliveryWhenReady) {
      this.setState({ showOptions: VIEW_CONFIRM, nextStatus, confirmTitle: CONFIRM_TITLE_DO_YOU_WANT_TO_START.replace('_TIME_', timeUntil) });
      return false;
    }

    if (nextStatus === ORDER_STATUS_PRODUCTION_FINISHED
      && !order.delivery.table
      && !confirmed
      && order.timeLeftMinutes > 1
      && !order.delivery.deliveryWhenReady) {
      this.setState({ showOptions: VIEW_CONFIRM, nextStatus, confirmTitle: CONFIRM_TITLE_X_MINUTES_TO_DESIRED_DELIVERY_TIME.replace('_TIME_', timeUntil) });
      return false;
    }
    this.setState({ confirmed: false, confirmTitle: null });
    return true;
  }

  toggleOptions(optionsView) {
    const { selectedReport, currentOptionsView } = this.state;
    if (typeof optionsView !== 'string') {
      // closing view (report view or options "sub" view or options view itself)
      if (selectedReport) {
        this.setState({ selectedReport: null });
      } else if (currentOptionsView) {
        this.setState({ currentOptionsView: null });
      } else {
        this.setState({ showOptions: null });
      }
    } else {
      // opening view
      this.setState({ showOptions: optionsView });
    }
  }

  userInteractionHandler() {
    const {
      userIdle,
      continuousUrgencyAlertForOrders,
      currentView,
      pob,
      locked,
      orders,
      localTime,
    } = this.state;

    if (userIdle && !locked) {
      analytics.track({
        name: continuousUrgencyAlertForOrders.length > 0
          ? 'Close full screen notification with continuous alert'
          : 'Close full screen notification',
        value: '',
        screen: currentView,
        pobId: pob ? pob.pobId : '',
      });
    }

    if (userIdle && locked && orders instanceof Error) {
      analytics.track({
        name: 'Close full screen error',
        value: '',
        screen: currentView,
        pobId: pob ? pob.pobId : '',
      });
      window.location.reload();
    }

    this.setState({
      lastUserInteraction: localTime,
      // reset userIdle flag if was on
      ...(userIdle && { userIdle: false }),
      // Reset continuousUrgencyAlertForOrders if closing userIdle full screen notification
      ...(userIdle && continuousUrgencyAlertForOrders.length > 0 && { continuousUrgencyAlertForOrders: [] }),
    });
  }

  lock() {
    const { currentView, pob } = this.state;
    this.setState({
      locked: true,
      userIdle: true,
    });
    appLock.lock();
    analytics.track({
      name: 'Lock app screen',
      value: '',
      screen: currentView,
      pobId: pob ? pob.pobId : '',
    });
  }

  unlock() {
    const { currentView, pob } = this.state;
    this.setState({ locked: false });
    analytics.track({
      name: 'Unlock app screen',
      value: '',
      screen: currentView,
      pobId: pob ? pob.pobId : '',
    });
  }

  togglePortionAvailability(ean, available) {
    console.log('togglePortionAvailability', ean);
    const { pob } = this.state;
    const pobId = pob ? pob.pobId : '';
    this.setState({ loaderRunning: true });
    if (pobId.length > 0) {
      this.updateItemForBlacklist({ pobId, ean, available: !available });
    }
  }

  updateItemForBlacklist({ pobId, ean, available }) {
    const { menu } = this.state;
    try {
      Api.toggleBlacklistForEan(ean, available, (res) => {
        const { blacklistedPortionEans } = res;
        const portionGroups = menu.portionGroups.map(pg => ({
          ...pg,
          portions: pg.portions.map(portion => ({ ...portion, available: blacklistedPortionEans.indexOf(portion.ean) === -1 })),
        }));
        this.setState({ menu: { ...menu, portionGroups: [...portionGroups] }, loaderRunning: false });
        console.log('blacklist updated for pob:', pobId, 'blackList', blacklistedPortionEans);
      }, (error) => {
        this.setState({ loaderRunning: false });
        console.error('updating blacklist failed', error);
      });
    } catch (error) {
      console.error(`error trying to update blacklist for pobId: ${pobId}, ean: ${ean}, error: ${error}`);
    }
  }

  render() {
    const {
      showOptions,
      currentOptionsView,
      currentView,
      pob,
      orders,
      buffetOrders,
      localTime,
      selectedOrder,
      pendingActions,
      loaderRunning,
      userIdle,
      viewOrders,
      viewOrdersByUrgencyLevel,
      locked,
      nextStatus,
      confirmTitle,
      menu,
      marketOrders,
    } = this.state;
    let OptionsView;
    if (showOptions === VIEW_OPTIONS_VIEWS) {
      switch (currentOptionsView) {
        case VIEW_CASHIER_REPORT:
          OptionsView = (
            <CashierReportView
              onCashierReportViewExit={this.toggleOptions}
            />
          );
          break;
        case VIEW_CHANGE_PIN:
          OptionsView = (
            <ChangePinView
              onPinChanged={this.onPinChanged}
              onBackClick={this.toggleOptions}
            />
          );
          break;
        case VIEW_MANAGE_PORTIONS:
          OptionsView = (
            <BlacklistView togglePortionAvailability={this.togglePortionAvailability} menu={menu} toggleOptions={this.toggleOptions} />
          );

          break;
        default:
          OptionsView = App.viewOptionsView({
            currentView,
            onViewSelect: this.onViewSelect,
            currentOptionsView,
            onOptionsViewSelect: this.onOptionsViewSelect,
            managePortionsTitle: getBlacklistHeaderText(menu),
          });
          break;
      }
    }

    const QueueOptionsView = showOptions === VIEW_OPTIONS_QUEUE
      ? <QueueOptions currentQueue={pob.foodQueueMinutes} onQueueSelect={this.onQueueSelect} />
      : null;

    const OrderSettingsView = showOptions === VIEW_ORDER_SETTINGS
      ? <OrderSettings printBong={() => App.printOrder(selectedOrder)} />
      : null;

    const ConfirmView = showOptions === VIEW_CONFIRM
      ? (<Confirm onClick={() => { this.setState({ showOptions: null, confirmed: true }, () => this.moveToStatus(selectedOrder, nextStatus)); }} onCancel={() => this.setState({ showOptions: null })} title={confirmTitle} />)
      : null;

    const BuffetView = showOptions === VIEW_BUFFETS
      ? <Buffets orders={buffetOrders} />
      : null;

    const MarketOrdersView = showOptions === VIEW_MARKET_ORDERS ? <MarketOrders orders={marketOrders} /> : null;

    const LoaderView = orders === null || menu === null ? App.loaderView() : null;

    if (!LoaderView && !OptionsView && !QueueOptionsView && !OrderSettingsView && !ConfirmView
      && userIdle) {
      if (orders instanceof Error) {
        return (
          <FullScreenError
            title="Yhteysvirhe"
            closeButtonText="Yritä uudestaan"
            description={orders.message}
          />
        );
      }
      const buffetOrderAmount = buffetOrders ? buffetOrders.reduce((acc, o) => acc + o.items.length, 0) : 0;
      const mostUrgentMarketOrder = marketOrders && marketOrders.length > 0 && marketOrders[0];
      if (viewOrdersByUrgencyLevel) {
        const notificationForUrgencyLevel = URGENCY_LEVELS.slice().reverse().find(ul => viewOrdersByUrgencyLevel[ul]);
        if (notificationForUrgencyLevel) {
          const firstOrderOfUrgencyLevel = viewOrdersByUrgencyLevel[notificationForUrgencyLevel][0];
          const firstOrderOfUrgencyLevelItemCount = resolveOrderItems(firstOrderOfUrgencyLevel, true).length;
          const hasDeliveryWhenReady = hasDeliveryWhenReadyInQueue(orders);
          const portionTimeInfo = timeInMinutesOrTime(
            firstOrderOfUrgencyLevel.timeLeftMinutes,
            firstOrderOfUrgencyLevel.delivery.time,
          );
          const portionCountInfo = firstOrderOfUrgencyLevelItemCount === 1
            ? '1 annos'
            : `${firstOrderOfUrgencyLevelItemCount} annosta`;
          const title = hasDeliveryWhenReady
            ? 'HETI'
            : portionTimeInfo;
          const descriptions = hasDeliveryWhenReady
            ? [portionCountInfo, portionTimeInfo]
            : [portionCountInfo];
          return (
            <React.Fragment>
              <BlacklistBanner menu={menu} visible={(menu && getBlacklistedPortionCount(menu)) > 0} />
              <FullScreenNotification
                splitView={!!mostUrgentMarketOrder}
                marketOrderUrgency={mostUrgentMarketOrder && mostUrgentMarketOrder.urgencyLevel}
                marketOrderTime={mostUrgentMarketOrder && timeInMinutesOrTime(mostUrgentMarketOrder.remainingMinutes, mostUrgentMarketOrder.deadline)}
                urgency={notificationForUrgencyLevel}
                title={title}
                descriptions={descriptions}
                closeButtonText="Näytä tilaukset"
                buffetOrderAmount={buffetOrderAmount}
                tableNumberGiven={!!firstOrderOfUrgencyLevel.delivery.table}
              />
            </React.Fragment>
          );
        }
      }

      return (
        <React.Fragment>
          <BlacklistBanner menu={menu} visible={(menu && getBlacklistedPortionCount(menu)) > 0} />
          <FullScreenNotification
            splitView={!!mostUrgentMarketOrder}
            marketOrderUrgency={mostUrgentMarketOrder && mostUrgentMarketOrder.urgencyLevel}
            marketOrderTime={mostUrgentMarketOrder && timeInMinutesOrTime(mostUrgentMarketOrder.remainingMinutes, mostUrgentMarketOrder.deadline)}
            title="Ei tilauksia"
            closeButtonText="Avaa"
            buffetOrderAmount={buffetOrderAmount}
            tableNumberGiven={false}
          />
        </React.Fragment>
      );
    }

    if (locked) {
      return (<LockedView onSuccessfulPinEntry={this.unlock} />);
    }

    const ErrorView = orders instanceof Error ? App.errorView({ error: orders }) : null;

    return (
      <React.Fragment>
        {menu && !showOptions && (
          <BlacklistBanner
            visible={(menu && !showOptions && getBlacklistedPortionCount(menu)) > 0}
            menu={menu}
          />
        )}
        <div className="flexbox-parent">
          {!currentOptionsView && !ConfirmView && (
          <Header
            time={localTime}
            toggleOptions={this.toggleOptions}
            showOptions={showOptions}
            currentView={currentView}
            currentQueue={pob && pob.foodQueueMinutes}
            onLockClick={this.lock}
            locked={locked}
            buffetOrders={buffetOrders}
            marketOrders={marketOrders}
          />
          )}

          {OptionsView || QueueOptionsView || ErrorView || LoaderView
        || OrderSettingsView || ConfirmView || BuffetView || MarketOrdersView || (
          <OrdersView
            ordersWithUrgencyByStatuses={viewOrders}
            selectOrder={this.onOrderSelect}
            selectedOrder={selectedOrder}
            onOrderListScroll={this.userInteractionHandler}
            moveToStatus={this.moveToStatus}
            time={localTime}
            pendingActions={pendingActions}
            toggleOptions={this.toggleOptions}
          />
          )}

          <ToastContainer autoClose={false} draggablePercent={50} />

          <div className={`loader ld-over-full${loaderRunning ? ' running' : ''}`}>
            <div className="ld ld-ball ld-flip" />
          </div>

        </div>
      </React.Fragment>
    );
  }
}

App.propTypes = {
  initialTime: PropTypes.instanceOf(moment).isRequired,
  locked: PropTypes.bool,
  initialView: PropTypes.oneOf(Object.keys(VIEWS)),
};

App.defaultProps = {
  locked: false,
  initialView: undefined,
};
