import { BrowserCacheLocation, InteractionRequiredAuthError, PublicClientApplication } from '@azure/msal-browser';

const msalConfig = {
  auth: {
    clientId: process.env.AZURE_CLIENT_ID,
    authority: 'https://login.microsoftonline.com/a652adc3-7bb3-4312-8eb0-6ab323f7d6cd',
    redirectUri: process.env.REDIRECT_URI,
  },
  cache: {
    cacheLocation: BrowserCacheLocation.LocalStorage,
  }
};

console.log('msalConfig', msalConfig);
export const msalInstance = new PublicClientApplication(msalConfig);

const getScopes = () => [`${process.env.AZURE_CLIENT_ID}/.default`];

export const getLoginRequest = () => {
  return {
    scopes: getScopes(),
    account: getActiveAccount(),
    forceRefresh: false,
    // refreshTokenExpirationOffsetSeconds: 24*60*60 - 60, // debug 24h - 1min for debug purposes require almost 24h expiry
  };
}

const getSilentLoginRequest = () => {
  return {
    ...getLoginRequest(),
    redirectUri: process.env.REDIRECT_URI_SILENT,
  };
}

const getActiveAccount = () => {
  console.log('getActiveAccount');
  return msalInstance.getActiveAccount() || msalInstance.getAllAccounts()[0];
}

export const getToken = async () => {
  console.log('getToken');
  const account = getActiveAccount();

  if (!account) {
    console.error('No active account');
    /*
    * User is not signed in. Throw error or wait for user to login.
    * Do not attempt to log a user in outside of the context of MsalProvider
    */
    return;
  }
  
  const request = getSilentLoginRequest();
  console.log('acquireTokenSilent', request);

  const authResult = await msalInstance.acquireTokenSilent(getSilentLoginRequest());
  return authResult.accessToken;
};

export const getCachedUser = () => {
  const account = getActiveAccount();
  return account ? account.username : undefined;
};

export const isErrorInteractionRequired = (error) => {
  return error instanceof InteractionRequiredAuthError // MSASL
    || error?.message?.match(/AADSTS50058/); // ADAL
};
