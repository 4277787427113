const mockTracker = () => {};

const tracker = (...params) => {
  console.log('tracker', JSON.stringify(params, null, 2));
  const useTracker = window.snowplow || mockTracker;
  useTracker(...params);
};

let lastKnownPobId = '';

const setLastKnownPobId = (pobId) => {
  lastKnownPobId = pobId;
};

const commonData = {
  app_name: process.env.SNOWPLOW_APP_NAME,
  app_version: process.env.REV || 'unknown',
  app_language: 'fi',
};


// Schemas here: https://gitlab.sok.fi/sok-oc/snowplow-schemas/-/tree/main/schemas/fi.sok

const track = ({
  name,
  value,
  screen,
  pobId,
}) => {
  tracker('trackSelfDescribingEvent', {
    schema: 'iglu:fi.sok/abc-mobiili-event/jsonschema/1-0-0',
    data: {
      ...commonData,
      name,
      value: `${value || ''}`,
      screen,
      pob_id: pobId || lastKnownPobId || '',
    },
  });
};

// This was used: https://docs.snowplow.io/docs/sources/trackers/javascript-trackers/web-tracker/tracking-events/errors/
// But didn't see any error object details (even the message) in Splunk
const trackError = (message, filename, error) => track({
  name: 'trackError',
  value: `${message} - ${error && error.message ? error.message : ''}`,
  screen: filename,
});

const trackPageView = (page, pobId) => tracker('trackPageView', page, [{
  schema: 'iglu:fi.sok/abc-mobiili-screen/jsonschema/1-0-0',
  data: {
    ...commonData,
    pob_id: pobId,
  },
}]);

export default {
  track,
  trackError,
  trackPageView,
  setLastKnownPobId,
};
