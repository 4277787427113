import React from 'react';
import ReactDOM from 'react-dom';
import moment from 'moment-timezone';
import { MsalProvider, MsalAuthenticationTemplate } from '@azure/msal-react';
import { EventType, InteractionType } from '@azure/msal-browser';
import { msalInstance, getLoginRequest } from './auth/msal-config';

import appLock from './app-lock';

import { getInitialView } from './util';

import App from './appIndex';

moment.tz.setDefault('Europe/Helsinki');

// hard coded pin for the time being
if (appLock.hasPin() === false) appLock.setPin('4444');

function ErrorComponent({error}) {
  return <p>An Error Occurred: {error?.message}</p>;
}

function LoadingComponent() {
  return <p>Authentication in progress...</p>;
}

const AppProvider = ({pca}) => (
  <MsalProvider instance={pca}>
    <MsalAuthenticationTemplate
      interactionType={InteractionType.Redirect}
      errorComponent={ErrorComponent} 
      loadingComponent={LoadingComponent}
      authenticationRequest={getLoginRequest()}
    >
      <App
        initialTime={moment()}
        locked={appLock.isLocked()}
        initialView={getInitialView()}
      />
    </MsalAuthenticationTemplate>
  </MsalProvider>
);

console.log('msalInstance initialize');
msalInstance.initialize().then(() => {
  console.log('msalInstance initialized');
  // Default to using the first account if no account is active on page load
  if (!msalInstance.getActiveAccount() && msalInstance.getAllAccounts().length > 0) {
    // Account selection logic is app dependent. Adjust as needed for different use cases.
    const account = msalInstance.getAllAccounts()[0];
    console.log('Set active account', account)
    msalInstance.setActiveAccount(account);
  }

  // Optional - This will update account state if a user signs in from another tab or window
  // msalInstance.enableAccountStorageEvents();

  msalInstance.addEventCallback((event) => {
    console.log('msalInstance event', event);
    if (event.eventType === EventType.LOGIN_SUCCESS
      ||
      event.eventType === EventType.ACQUIRE_TOKEN_SUCCESS
      ||
      event.eventType === EventType.SSO_SILENT_SUCCESS
    ) {
      console.log('Login success', event.eventType);
      if (event.payload?.account) {
        const account = event.payload.account;
        console.log('Login success account', account);
        msalInstance.setActiveAccount(account);
      }
    }
  });

  ReactDOM.render(<AppProvider pca={msalInstance}/>, document.getElementById('root'));
});

// Hot Module Replacement
if (module.hot) {
  module.hot.accept();
}
