import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import moment from 'moment-timezone';

import { getToken, isErrorInteractionRequired } from '../../auth/msal-config';

import { MONTHS } from '../../constants';
import { I18n } from '../../util';
import { ReportShape } from './shapes';

import analytics from '../../analytics';

import './list.css';
import Checkbox from '../checkbox';
import Notification from '../notification';

const groupReportsByMonth = reports => reports.reduce((acc, report) => {
  const yearMonthKey = report.date.substr(0, 7);
  const yearMonthReports = acc[yearMonthKey];
  return {
    ...acc,
    [yearMonthKey]: yearMonthReports ? [...yearMonthReports, report] : [report],
  };
}, {});
const resolveCompletedDateTimeString = (report) => {
  if (!report.completed) {
    return '';
  }
  const completedDate = moment(report.completed);
  return (moment(report.date).isSame(completedDate) === false)
    ? I18n.dateTime.format(completedDate)
    : I18n.time.format(completedDate);
};

const Report = ({ report, key, onReportSelect }) => {
  const statusValues = Object.values(report.status);
  const allDone = statusValues.every(value => value === 'DONE');
  const someDone = statusValues.some(value => value === 'DONE');
  let statusText;
  if (!allDone) {
    statusText = someDone ? 'Kesken' : 'Lyömättä';
  }

  return (
    <div
      className={classNames('report-list__row', {
        'report-list__row_unfinished': !allDone && someDone,
        'report-list__row_done': allDone,
      })}
      key={key}
      data-test-key={`cashier-report-list-${report.date}`}
      onClick={() => onReportSelect(report)}
    >
      <div className="report-list-row__checkbox">
        <Checkbox
          checked={allDone || someDone}
          id={report.date}
        />
      </div>
      <div className="report-list-row__date">{I18n.date.format(moment(report.date))}</div>
      <div className="report-list-row__completed">{resolveCompletedDateTimeString(report)}</div>
      <div className="report-list-row__status">{statusText || I18n.currency.format(report.sum / 100)}</div>
      <div className="report-list-row__arrow-right" />
    </div>
  );
};

Report.propTypes = {
  report: PropTypes.shape(ReportShape).isRequired,
  key: PropTypes.string.isRequired,
  onReportSelect: PropTypes.func.isRequired,
};

const MontlhlyReports = ({
  reports,
  currentYearAndMonth,
  yearAndMonth,
  onReportSelect,
}) => {
  const showSubheader = currentYearAndMonth !== yearAndMonth;
  return (
    <div
      className="monthly-report-list"
      data-test-key={`cashier-report-list_${yearAndMonth}`}
      key={`monthly-report-list-${yearAndMonth}`}
    >
      {showSubheader && (
        <div className="monthly-report-list__subheader">
          {yearAndMonth.substr(0, 4)}
          &nbsp;
          {MONTHS[yearAndMonth.substr(5, 2)]}
        </div>
      )}
      <div className="monthly-report-list__rows">
        {reports.map((report, key) => Report({ report, key, onReportSelect }))}
      </div>
    </div>
  );
};

MontlhlyReports.propTypes = {
  reports: PropTypes.arrayOf(PropTypes.shape(ReportShape)).isRequired,
  currentYearAndMonth: PropTypes.string.isRequired,
  yearAndMonth: PropTypes.string.isRequired,
  onReportSelect: PropTypes.func.isRequired,
};

MontlhlyReports.displayName = 'MontlhlyReports';

const CashierReportList = ({ onReportSelect }) => {
  const [reports, setReports] = useState(null);

  useEffect(() => {
    getToken().then((token) => {
      fetch(`${process.env.REST_API_BASE}/food/v1/cashier/entries`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      }).then(res => res.json())
        .then(setReports)
        .catch((error) => {
          analytics.trackError(
            'Could not fetch cashier entries',
            'cashier-report/list.jsx',
            error,
          );
          setReports(error);
        });
    }).catch((error) => {
      if (isErrorInteractionRequired(error)) {
        window.location.reload();
      }
    });
  }, []);

  const currentYearAndMonth = moment().format('YYYY-MM');

  if (!reports) {
    return (<Notification title="Haetaan kassaraportteja" />);
  }

  if (reports instanceof Error) {
    return (<Notification title="Kassaraporttien hakeminen epäonnistui" />);
  }

  return (
    <div
      className="flexbox-item-grow cashier-report-list"
      data-test-key="cashier-report-list"
    >
      <span className="cashier-report-list__title">Kassaraportit</span>
      <div className="cashier-report-list__content">
        {Object.entries(groupReportsByMonth(reports))
          .map(([yearAndMonth, monthReports]) => MontlhlyReports({
            reports: monthReports,
            currentYearAndMonth,
            yearAndMonth,
            onReportSelect,
          }))}
      </div>
    </div>
  );
};

CashierReportList.propTypes = {
  onReportSelect: PropTypes.func.isRequired,
};

CashierReportList.displayName = 'CashierReportList';

export {
  CashierReportList,
  MontlhlyReports,
};

export default React.memo(CashierReportList);
