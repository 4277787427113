import { fetchPortionBlacklistForPobViaRest, toggleBlacklistForEanViaRest } from './rest/blacklist';
import { getToken } from '../auth/msal-config';

const fetchPortionBlacklistForPob = (callback) => {
  getToken().then(token => fetchPortionBlacklistForPobViaRest(token)
    .then(res => res.json())
    .then(callback)
    .catch((error) => {
      console.error('error fetching blacklist for station', error);
    }));
};

const toggleBlacklistForEan = (ean, available, callback, errorCallback) => {
  getToken().then(token => toggleBlacklistForEanViaRest(token, ean, available))
    .then(res => res.json())
    .then(callback)
    .catch(errorCallback);
};


export default { fetchPortionBlacklistForPob, toggleBlacklistForEan };
