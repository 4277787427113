import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import moment from 'moment-timezone';

import { getToken } from '../../auth/msal-config';

import './report.css';
import CashierReportViewHeader from './header';
import Checkbox from '../checkbox';
import Notification from '../notification';

import analytics from '../../analytics';

import { I18n } from '../../util';
import { PRICE_TYPES, PRICE_TYPE_TEXTS, VIEW_CASHIER_REPORT } from '../../constants';
import { ReportShape, PriceTypeDataShape, ReportDetailsShape } from './shapes';

const priceTypesTemplate = PRICE_TYPES.map(priceType => ({
  categories: [],
  priceType,
  sum: 0,
}));

const PriceTypeTabContent = ({ checkedEans, onEanCheckedChange, priceType }) => (
  <div className="cashier-report-report__categories">
    {priceType.categories.map(({ category, items }) => (
      <div key={`category-${category}`}>
        <div className="report_category__subheader">{category}</div>
        <div className="report_category__items">
          {items.map(({
            count, ean, name, receiptName, sum, orderInfo = {}, originalSum,
          }) => {
            const itemChecked = Boolean(checkedEans[`${ean}-${sum}`]);
            const className = classNames('report_category__item', {
              report_category__item_selected: itemChecked,
            });
            return (
              <div
                key={`item-${ean}-${sum}`}
                data-test-key={`report_category__item-${ean}`}
                className={className}
                onClick={(e) => {
                  onEanCheckedChange(`${ean}-${sum}`, !itemChecked);
                  e.preventDefault();
                }}
              >
                <div className="category_item__cell_checked">
                  <Checkbox
                    checked={itemChecked}
                    id={ean}
                  />
                </div>
                <div className="category_item__cell_count">
                  {`${count} x`}
                </div>
                <div className="category_item__cell_name">
                  {receiptName || name}
                </div>
                {orderInfo && orderInfo.discountPercentage && (
                  <div className="category_item__cell_discount">
                    {`-${orderInfo.discountPercentage}%`}
                  </div>
                )}
                <div className="category_item__cell_sum" data-test-key={`category_item__sum-${ean}`}>
                  <div className="category_item__cell_sum__discounted">{I18n.currency.format(sum / 100)}</div>
                  {orderInfo && orderInfo.discountPercentage && (
                    <div className="category_item__cell_sum__original">
                      {I18n.currency.format(originalSum / 100)}
                    </div>
                  )}
                </div>
              </div>
            );
          })}
        </div>
      </div>
    ))}
  </div>
);

PriceTypeTabContent.propTypes = {
  checkedEans: PropTypes.shape({}).isRequired,
  onEanCheckedChange: PropTypes.func.isRequired,
  priceType: PropTypes.shape({
    categories: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    priceType: PropTypes.oneOf(PRICE_TYPES),
    sum: PropTypes.number.isRequired,
  }).isRequired,
};

const PriceTypeTab = ({
  priceType,
  selected,
  index,
  count,
  onTabSelect,
}) => (
  <div
    key={`price-type-${priceType.priceType}`}
    data-test-key={`tab-${priceType.priceType}`}
    className={classNames('pricetype__tab', {
      pricetype__tab_selected: selected,
      pricetype__tab_left: index === 0,
      pricetype__tab_center: count % 2 > 0 && index === Math.floor(count / 2),
      pricetype__tab_right: index === count - 1,
    })}
    onClick={() => !selected && onTabSelect(priceType.priceType)}
  >
    {PRICE_TYPE_TEXTS[priceType.priceType] || priceType.priceType}
    :&nbsp;
    {I18n.currency.format(priceType.sum / 100)}
  </div>
);
PriceTypeTab.propTypes = {
  priceType: PropTypes.oneOf(PRICE_TYPES).isRequired,
  selected: PropTypes.bool.isRequired,
  index: PropTypes.number.isRequired,
  count: PropTypes.number.isRequired,
  onTabSelect: PropTypes.func.isRequired,
};

const isTrue = value => value === true;

const CashierReportConfirmation = ({
  checkedEansTotalSum,
  handleCancelAllDone,
  handleConfirmAllDone,
}) => (
  <React.Fragment>
    <CashierReportViewHeader onBackClick={handleCancelAllDone} />
    <div className="cashier-report-report cashier-report-report__confirm">
      <div className="report-confirm__title">Sama summa kassassa?</div>
      <div className="report-confirm__sum">{I18n.currency.format(checkedEansTotalSum / 100)}</div>
      <div className="report-confirm__buttons">
        <div
          className="report-confirm__button report-confirm__button_negative"
          data-test-key="cashier-report-confirm__cancel"
          onClick={handleCancelAllDone}
        >
          Takaisin
        </div>
        <div
          className="report-confirm__button report-confirm__button_positive"
          data-test-key="cashier-report-confirm__ok"
          onClick={() => handleConfirmAllDone(checkedEansTotalSum)}
        >
          Summa täsmää
        </div>
      </div>
    </div>
  </React.Fragment>
);

CashierReportConfirmation.propTypes = {
  checkedEansTotalSum: PropTypes.number.isRequired,
  handleCancelAllDone: PropTypes.func.isRequired,
  handleConfirmAllDone: PropTypes.func.isRequired,
};

const CashierReportPortionList = ({
  report,
  currentPriceType,
  priceTypeData,
  checkedEansTotalSum,
  checkedEans,
  onCashierReportClose,
  onTabSelect,
  onEanCheckedChange,
  onAllDoneClick,
  currentReport,
}) => {
  const checkedEanCount = Object.values(checkedEans[currentPriceType] || {}).filter(isTrue).length;
  const totalEanCount = priceTypeData.categories.reduce((sum, c) => sum + c.items.length, 0);
  const allDone = checkedEanCount === totalEanCount;
  const totalSum = report ? report.priceTypes.reduce((acc, { sum }) => acc + sum, 0) : 0;
  const currentPriceTypeDone = currentReport.status[currentPriceType]
    && currentReport.status[currentPriceType] === 'DONE';

  return (
    <React.Fragment>
      <div
        className="cashier-report-report"
        data-test-key={`cashier-report-details-${report.date}`}
      >
        <div className="cashier-report-report__header">
          <div className="report-header__top-row">
            <div className="button button-positive" onClick={onCashierReportClose}>Takaisin</div>
            <div className="report-header__title">
              <span className="report-header__date">
                {I18n.date.format(moment(report.date))}
              </span>
              &nbsp;kokonaismyynti oli&nbsp;
              <span className="report-header__total-sum">
                {I18n.currency.format(totalSum / 100)}
              </span>
            </div>
          </div>
          <div className="cashier-report-report__tabs">
            {report.priceTypes.map((priceType, index) => PriceTypeTab({
              priceType,
              selected: priceType.priceType === currentPriceType,
              index,
              count: PRICE_TYPES.length,
              onTabSelect,
            }))}
          </div>
          {(currentReport.completed) && (
            <div className="cashier-report-report__modified">
              {`Päivän myynti ${I18n.currency.format(currentReport.sum / 100)} 
              lyöty kassaan ${I18n.dateTime.format(moment(currentReport.completed))}`}
            </div>
          )}
          {(!currentReport.completed && currentPriceTypeDone) && (
            <div className="cashier-report-report__modified">
              {`${PRICE_TYPE_TEXTS[currentPriceType]} lyöty kassaan`}
            </div>
          )}

        </div>
        <div className="cashier-report-report__content-container">
          <div className="cashier-report-report__content">
            <PriceTypeTabContent
              priceType={priceTypeData}
              checkedEans={checkedEans[currentPriceType] || {}}
              onEanCheckedChange={onEanCheckedChange}
            />
          </div>
        </div>
        <div className="report__footer">
          {!allDone && (
            <div
              className="footer__button"
              data-test-key="cashier-report__price-type-submit"
            >
              {checkedEanCount}
              /
              {totalEanCount}
            </div>
          )}
          {allDone && !currentPriceTypeDone && (
            <div
              className="footer__button footer__button_enabled"
              data-test-key="cashier-report__price-type-submit"
              onClick={onAllDoneClick}
            >
              Lyö kassaan
            </div>
          )}
          {allDone && currentPriceTypeDone && (
            <div
              className="footer__button footer__button_disabled"
              data-test-key="cashier-report__price-type-submit"
            >
              Lyöty jo kassaan
            </div>
          )}
          <div className="footer__sum">
            <div data-test-key="cashier-report__price-type-sum">
              {I18n.currency.format(checkedEansTotalSum / 100)}
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

CashierReportPortionList.propTypes = {
  report: PropTypes.shape(ReportDetailsShape).isRequired,
  currentPriceType: PropTypes.oneOf(PRICE_TYPES).isRequired,
  priceTypeData: PropTypes.shape(PriceTypeDataShape).isRequired,
  checkedEansTotalSum: PropTypes.number.isRequired,
  checkedEans: PropTypes.shape({}).isRequired,
  onCashierReportClose: PropTypes.func.isRequired,
  onTabSelect: PropTypes.func.isRequired,
  onEanCheckedChange: PropTypes.func.isRequired,
  onAllDoneClick: PropTypes.func.isRequired,
  currentReport: PropTypes.shape(ReportShape).isRequired,
};

export const CashierReportEdit = ({
  currentReport,
  report,
  onCashierEntryUpdate,
  onCashierReportClose,
}) => {
  const [firstNotDonePriceType] = Object.entries(currentReport.status).find(([, status]) => status === 'NOT_DONE')
    || [];
  const [currentPriceType, setCurrentPriceType] = useState(firstNotDonePriceType || report.priceTypes[0].priceType);
  const [checkedEans, setCheckedEans] = useState({});
  const [confirmation, setConfirmation] = useState(false);
  const [updateError, setUpdateError] = useState();

  let isSubscribed = true;
  React.useEffect(() => () => { isSubscribed = false; });

  const handleAllDoneClick = () => {
    setConfirmation(true);
  };
  const handleCancelAllDone = () => setConfirmation(false);
  const handleConfirmAllDone = (sum) => {
    const currentSum = currentReport.sum || 0;
    const cashierEntry = {
      ...currentReport,
      sum: currentSum + sum,
      status: {
        ...currentReport.status,
        [currentPriceType]: 'DONE',
      },
    };
    getToken().then((token) => {
      fetch(`${process.env.REST_API_BASE}/food/v1/cashier/entry`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(cashierEntry),
      }).then(res => res.json())
        .then((updatedCashierEntry) => {
          analytics.track({
            name: updatedCashierEntry.completed
              ? 'Finish cashier entry'
              : 'Save cashier entry',
            value: updatedCashierEntry.date,
            screen: VIEW_CASHIER_REPORT,
            pobId: report.pobId,
          });
          onCashierEntryUpdate(updatedCashierEntry);
          if (isSubscribed) {
            setConfirmation(false);
          }
        }).catch((error) => {
          analytics.trackError(
            'Could not save cashier report',
            'cashier-report/report.jsx',
            error,
          );
          if (isSubscribed) {
            setUpdateError(error);
          }
        });
    });
  };
  const handleEanCheckedChange = (ean, checked) => setCheckedEans({
    ...checkedEans,
    [currentPriceType]: checkedEans[currentPriceType]
      ? { ...checkedEans[currentPriceType], [ean]: checked }
      : { [ean]: checked },
  });

  const handlePriceTabSelect = (priceTab) => {
    analytics.track({
      name: 'Select cashier report price type tab',
      value: priceTab,
      pobId: report.pobId,
      screen: VIEW_CASHIER_REPORT,
    });
    setCurrentPriceType(priceTab);
  };

  const handleErrorClose = () => setUpdateError(null);

  const priceTypeData = report.priceTypes.find(({ priceType }) => priceType === currentPriceType);

  const checkedEansTotalSum = priceTypeData.categories
    .reduce((sum, c) => sum + c.items
      .reduce((cSum, i) => (checkedEans[currentPriceType] && checkedEans[currentPriceType][`${i.ean}-${i.sum}`]
        ? cSum + i.sum
        : cSum
      ), 0),
    0);

  if (updateError instanceof Error) {
    return (
      <Notification
        title="Kassaraportin tallentaminen epäonnistui"
        action={{
          text: 'Sulje',
          onClick: handleErrorClose,
        }}
      />
    );
  }

  if (confirmation) {
    return (
      <CashierReportConfirmation
        checkedEansTotalSum={checkedEansTotalSum}
        handleCancelAllDone={handleCancelAllDone}
        handleConfirmAllDone={handleConfirmAllDone}
      />
    );
  }

  return (
    <CashierReportPortionList
      onCashierReportClose={onCashierReportClose}
      report={report}
      currentPriceType={currentPriceType}
      onTabSelect={handlePriceTabSelect}
      priceTypeData={priceTypeData}
      checkedEans={checkedEans}
      onEanCheckedChange={handleEanCheckedChange}
      onAllDoneClick={handleAllDoneClick}
      checkedEansTotalSum={checkedEansTotalSum}
      currentReport={currentReport}
    />
  );
};

CashierReportEdit.propTypes = {
  currentReport: PropTypes.shape(ReportShape).isRequired,
  report: PropTypes.shape(ReportDetailsShape).isRequired,
  onCashierEntryUpdate: PropTypes.func.isRequired,
  onCashierReportClose: PropTypes.func.isRequired,
};

const addMissingPriceTypes = report => ({
  ...report,
  priceTypes: priceTypesTemplate.map(ptt => (
    report.priceTypes.find(pt => pt.priceType === ptt.priceType) || ptt
  )),
});

const CashierReport = ({
  currentReport,
  onCashierEntryUpdate,
  onCashierReportClose,
  handleReportFetchErrorClose,
}) => {
  console.log('CashierReport');
  const [report, setReport] = useState();

  useEffect(() => {
    let isSubscribed = true;
    getToken().then((token) => {
      const data = { date: currentReport.date };
      fetch(`${process.env.REST_API_BASE}/food/v1/aggregate/`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(data),
      }).then(res => res.json())
        .then(cashierReportEntry => isSubscribed && setReport(cashierReportEntry))
        .catch((error) => {
          if (isSubscribed) {
            analytics.trackError(
              'Could not load cashier report',
              'cashier-report/report.jsx',
              error,
            );
            setReport(error);
          }
        });
    });
    return () => { isSubscribed = false; };
  }, []);

  if (!report) {
    return (<Notification title="Haetaan kassaraporttia" />);
  }

  if (report instanceof Error) {
    return (
      <Notification
        title="Kassaraportin hakeminen epäonnistui"
        action={{
          text: 'Sulje',
          onClick: handleReportFetchErrorClose,
        }}
      />
    );
  }

  return (
    <CashierReportEdit
      currentReport={currentReport}
      report={addMissingPriceTypes(report)}
      onCashierEntryUpdate={onCashierEntryUpdate}
      onCashierReportClose={onCashierReportClose}
    />
  );
};

CashierReport.propTypes = {
  currentReport: PropTypes.shape(ReportShape).isRequired,
  onCashierEntryUpdate: PropTypes.func.isRequired,
  onCashierReportClose: PropTypes.func.isRequired,
  handleReportFetchErrorClose: PropTypes.func.isRequired,
};

export default React.memo(CashierReport);
